{
  "name": "CREATIVE CODING",
  "children": [
    {
      "name": "VISUEL",
      "ecal": true,
      "children": [
        {
          "name": "2D",
          "children": [
            {
              "name": "Javascript",
              "children": [
                { "name": "P5.js", "url": "https://p5js.org/", "ecal": true },
                {
                  "name": "Paper.js",
                  "url": "http://paperjs.org/",
                  "ecal": true
                },
                { "name": "Vanilla js", "ecal": true },
                {
                  "name": "vvvv.js",
                  "url": "http://vvvvjs.com/"
                },
                {
                  "name": "Canvas-sketch",
                  "url": "https://github.com/mattdesl/canvas-sketch"
                },
                { "name": "DOjS", "url": "https://github.com/SuperIlu/DOjS" },

                {
                  "name": "ASCII play",
                  "url": "https://play.ertdfgcvb.xyz/",
                  "ecal": true
                },
                {
                  "name": "Basiljs",
                  "url": "http://basiljs.ch/",
                  "ecal": false
                }
              ]
            },
            {
              "name": "C#",
              "children": [
                { "name": "Unity", "url": "https://unity.com/", "ecal": true }
              ]
            },
            {
              "name": "C++",
              "children": [
                {
                  "name": "OpenFrameworks",
                  "url": "https://openframeworks.cc/"
                },
                { "name": "Cinder", "url": "https://libcinder.org/" }
              ]
            },
            {
              "name": "Objective-c",
              "children": [{ "name": "Xcode" }]
            },
            { "name": "Swift", "children": [{ "name": "Xcode" }] },
            {
              "name": "Rust",
              "children": [{ "name": "Nannou", "url": "https://nannou.cc/" }]
            },
            {
              "name": "Python",
              "children": [
                {
                  "name": "Processing.py",
                  "url": "https://py.processing.org/"
                },
                { "name": "Shoebot", "url": "http://shoebot.net/" },

                {
                  "name": "Taichi Lang",
                  "url": "https://www.taichi-lang.org/"
                }
              ]
            },
            {
              "name": "Java",
              "children": [
                {
                  "name": "processing",
                  "url": "https://processing.org/",
                  "ecal": true
                }
              ]
            },
            {
              "name": "Node based",
              "children": [
                {
                  "name": "MaxMSP",
                  "url": "https://cycling74.com/products/max"
                },
                {
                  "name": "vvvv",
                  "url": "https://vvvv.org/"
                },
                {
                  "name": "Touch Designer",
                  "url": "https://derivative.ca/"
                },
                {
                  "name": "Nodebox",
                  "url": "https://www.nodebox.net/"
                },
                {
                  "name": "PureData",
                  "url": "https://puredata.info/"
                },
                {
                  "name": "vvvv.js",
                  "url": "http://vvvvjs.com/"
                },
                {
                  "name": "Nodes",
                  "url": "https://nodes.io/"
                },
                {
                  "name": "Cables",
                  "url": "https://cables.gl/"
                }
              ]
            },
            {
              "name": "Kotlin",
              "children": [
                { "name": "Openrndr", "url": "https://openrndr.org/" }
              ]
            },
            {
              "name": "Go",
              "children": [
                { "name": "GG", "url": "https://github.com/fogleman/gg" }
              ]
            },
            {
              "name": "Lisp",
              "children": [
                {
                  "name": "Quil",
                  "url": "https://quickref.common-lisp.net/quilc.html"
                }
              ]
            },
            {
              "name": "CSS",
              "children": [
                { "name": "CSS-Doodle", "url": "https://css-doodle.com/" }
              ]
            },
            {
              "name": "GdScript",
              "children": [
                { "name": "GDScript", "url": "https://gdscript.com/" }
              ]
            }
          ]
        },
        {
          "name": "3D",
          "children": [
            {
              "name": "Javascript",
              "children": [
                {
                  "name": "ThreeJS",
                  "url": "https://threejs.org/",
                  "ecal": true
                }
              ]
            },
            {
              "name": "C#",
              "children": [
                { "name": "Unity", "url": "https://unity.com/", "ecal": true }
              ]
            },
            {
              "name": "C++",
              "children": [
                {
                  "name": "Unreal Engine",
                  "url": "https://www.unrealengine.com/",
                  "ecal": true
                }
              ]
            },

            {
              "name": "Python",
              "children": [
                {
                  "name": "Blender",
                  "url": "https://www.blender.org/"
                },
                {
                  "name": "OpenSCAD",
                  "url": "https://openscad.org"
                }
              ]
            },
            {
              "name": "Node based",
              "children": [
                {
                  "name": "Houdini",
                  "url": "https://www.sidefx.com/products/houdini/"
                },
                {
                  "name": "Nodi",
                  "url": "https://nodi3d.com"
                },
                {
                  "name": "Blender",
                  "url": "https://www.blender.org/"
                },
                {
                  "name": "Rhino3D",
                  "url": "https://www.rhino3d.com/"
                },
                {
                  "name": "Grasshopper3D",
                  "url": "https://www.grasshopper3d.com/"
                },
                {
                  "name": "Cinema4D",
                  "url": "https://www.maxon.net/fr/cinema-4d",
                  "ecal": true
                },
                {
                  "name": "Substance Designer",
                  "url": "https://www.adobe.com/fr/products/substance3d-designer.html"
                }
              ]
            },
            {
              "name": "CSS",
              "children": [
                {
                  "name": "Threejs",
                  "url": "https://threejs.org/",
                  "ecal": true
                }
              ]
            },
            {
              "name": "swift",
              "children": [
                { "name": "Satin", "url": "https://github.com/Hi-Rez/Satin" }
              ]
            }
          ]
        },
        {
          "name": "AR",
          "children": [
            {
              "name": "Javascript",
              "children": [
                { "name": "A-Frame", "url": "https://aframe.io/" },
                {
                  "name": "WebXR",
                  "url": "https://immersive-web.github.io/webxr-samples/"
                }
              ]
            },
            {
              "name": "C#",
              "children": [{ "name": "Unity", "url": "https://unity.org/" }]
            },
            {
              "name": "C++",
              "children": [
                {
                  "name": "Openframeworks",
                  "url": "https://openframeworks.cc/"
                }
              ]
            }
          ]
        },
        {
          "name": "VR",
          "children": [
            {
              "name": "Javascript",
              "children": [{ "name": "ThreeJS", "url": "https://threejs.org/" }]
            },
            {
              "name": "C#",
              "children": [{ "name": "Unity", "url": "https://unity.org/" }]
            },
            {
              "name": "C++",
              "children": [
                {
                  "name": "Openframeworks",
                  "url": "https://openframeworks.cc/"
                }
              ]
            }
          ]
        },
        {
          "name": "DATAVIZ",
          "children": [
            {
              "name": "Javascript",
              "children": [
                { "name": "D3", "url": "https://d3js.org/" },
                { "name": "Highchart", "url": "https://www.highcharts.com/" }
              ]
            },
            {
              "name": "Python",
              "children": [
                { "name": "Bokeh", "url": "https://bokeh.org/" },
                { "name": "Plotly", "url": "https://plotly.com/" },
                { "name": "Matplotlib", "url": "https://matplotlib.org/" }
              ]
            },
            {
              "name": "R",
              "children": [
                { "name": "ggPlot", "url": "https://ggplot2.tidyverse.org/" }
              ]
            }
          ]
        },
        {
          "name": "MACHINE LEARNING",
          "children": [
            {
              "name": "Javascript",
              "children": [
                { "name": "ML5", "url": "https://ml5js.org/", "ecal": true },
                {
                  "name": "MediaPipe",
                  "url": "https://mediapipe.dev/",
                  "ecal": true
                }
              ]
            },
            {
              "name": "Python",
              "children": [
                {
                  "name": "PyTorch",
                  "url": "https://pytorch.org/",
                  "ecal": true
                },
                {
                  "name": "Tensorflow",
                  "url": "https://www.tensorflow.org/",
                  "ecal": true
                }
              ]
            }
          ]
        },
        {
          "name": "SHADERS",
          "children": [
            {
              "name": "C++",
              "children": [
                { "name": "Arnold", "url": "https://arnoldrenderer.com/" }
              ]
            },
            {
              "name": "WebGL/glsl",
              "children": [
                { "name": "Shadertoy", "url": "https://www.shadertoy.com/" }
              ]
            },
            {
              "name": "OpenGL"
            },
            {
              "name": "Lygia",
              "url": "https://github.com/patriciogonzalezvivo/lygia"
            }
          ]
        },
        {
          "name": "LIVE",
          "children": [
            {
              "name": "WebGL",
              "children": [
                { "name": "Hydra", "url": "https://hydra.ojack.xyz/" }
              ]
            },
            {
              "name": "Ruby",
              "children": [
                { "name": "Visor", "url": "https://www.visor.live/" }
              ]
            }
          ]
        },
        {
          "name": "WEB DEV",
          "children": [
            {
              "name": "Javascript",
              "children": [
                { "name": "Vanilla js", "ecal": true },
                { "name": "Jquery", "url": "https://jquery.com/" },
                { "name": "Mootools", "url": "https://mootools.net/" },
                { "name": "React", "url": "https://reactjs.org/" },
                { "name": "Vuejs", "url": "https://vuejs.org/" }
              ]
            },
            { "name": "Html", "ecal": true },
            { "name": "Css", "ecal": true }
          ]
        },
        {
          "name": "GAME DEV",
          "children": [
            {
              "name": "C#",
              "children": [
                { "name": "Unity", "url": "https://unity.org/", "ecal": true }
              ]
            },

            {
              "name": "Javascript",
              "children": [
                {
                  "name": "Construct",
                  "url": "https://www.construct.net/en"
                },
                {
                  "name": "ImpactJS",
                  "url": "https://impactjs.com/"
                },
                {
                  "name": "LittleJS",
                  "url": "https://killedbyapixel.github.io/LittleJS/docs/"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "name": "SOUND",
      "children": [
        {
          "name": "Javascript",
          "children": [
            {
              "name": "Tonejs",
              "url": "https://tonejs.github.io/",
              "ecal": true
            },
            { "name": "Wadjs", "url": "https://github.com/rserota/wad" },
            { "name": "Howlerjs", "url": "https://howlerjs.com/" },
            {
              "name": "Pizzicatojs",
              "url": "https://alemangui.github.io/pizzicato/"
            }
          ]
        },
        {
          "name": "Python",
          "children": [
            {
              "name": "Hypersonic",
              "url": "https://arrowtheory.com/software/hypersonic/index.html"
            },
            {
              "name": "Jythonmusic",
              "url": "https://jythonmusic.me/"
            },
            {
              "name": "Mingus",
              "url": "https://bspaans.github.io/python-mingus/index.html"
            },
            {
              "name": "BespokeSynth",
              "url": "https://www.bespokesynth.com/"
            }
          ]
        },
        {
          "name": "Node based",
          "children": [
            { "name": "PureData", "url": "https://puredata.info/" },
            {
              "name": "GameSynth",
              "url": "https://tsugi-studio.com/web/fr/products-gamesynth.html"
            },
            { "name": "MaxMSP", "url": "https://cycling74.com/products/max" }
          ]
        },
        {
          "name": "C++",
          "children": [
            { "name": "Juce", "url": "https://juce.com/" },
            {
              "name": "Maximilian",
              "url": "https://github.com/micknoise/Maximilian"
            },
            { "name": "STK", "url": "https://github.com/thestk/stk" },
            { "name": "Ossia", "url": "https://ossia.io/" }
          ]
        },
        { "name": "CSound", "url": "https://csound.com/" },
        { "name": "ChuCK", "url": "http://chuck.stanford.edu/" },
        { "name": "Orca", "url": "https://100r.co/site/orca.html" },
        {
          "name": "Lisp",
          "children": [
            {
              "name": "Alda",
              "url": "https://alda.readthedocs.io/en/latest/alda-lisp/"
            }
          ]
        },
        {
          "name": "Ruby",
          "children": [{ "name": "SonicPI", "url": "https://sonic-pi.net/" }]
        },
        { "name": "SuperCollider", "url": "https://supercollider.github.io/" }
      ]
    },
    {
      "name": "TANGIBLE",
      "children": [
        {
          "name": "Javascript",
          "children": [
            { "name": "Johnny Five", "url": "http://johnny-five.io/" }
          ]
        },
        {
          "name": "C++",
          "children": [
            {
              "name": "Arduino",
              "url": "https://www.arduino.cc/",
              "ecal": true
            },
            { "name": "RaspberryPI", "url": "https://www.raspberrypi.org/" }
          ]
        },
        {
          "name": "Python",
          "children": [
            { "name": "Vpype", "url": "https://github.com/abey79/vpype" }
          ]
        }
      ]
    },
    {
      "name": "HACKING",
      "children": [
        {
          "name": "Javascript",
          "children": [
            { "name": "Robotjs", "url": "http://robotjs.io/" },
            { "name": "Pupeteer", "url": "https://pptr.dev/" }
          ]
        }
      ]
    }
  ]
}
