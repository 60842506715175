import * as d3 from "d3";
import { reverse } from "d3";
// import EventEmitter from "../EventEmitter";

const ECAL_COLOR = "yellow";
export default class LinearTree {
  constructor(svgContainer) {
    // super();
    this.GET_ECAL = false;
    this.svgContainer = svgContainer;
  }

  initGraph(treeData, storyId = null) {
    console.log("init graph with data", treeData);
    const MIN_SCALE = 0.25;
    const MAX_SCALE = 10;
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.margin = {
      top: 80,
      right: 0,
      bottom: 0,
      left: 150,
    };

    let dx = 25;
    let dy = this.width / 5;
    this.tree = d3.tree().nodeSize([dx, dy]);
    this.diagonal = d3
      .linkHorizontal()
      .x((d) => d.y)
      .y((d) => d.x);

    const data = treeData;
    this.data = data;
    const root = d3.hierarchy(data, (d) => {
      // console.log(d)
      return d.children;
    });

    this.root = root;

    root.x0 = dy / 2;
    root.y0 = 0;
    console.log(root.descendants());
    root.descendants().forEach(async (d, i) => {
      d.id = i;
      d._children = d.children;
      d._prevChildren = d.children;
      if (d.depth) {
        d.children = null;
      }
    });

    this.svg = d3
      .create("svg")
      .attr("viewBox", [-this.margin.left, -this.margin.top, this.width, dx])
      .style("font", "14px sans-serif")
      .style("user-select", "none");

    this.svgContainer.appendChild(this.svg.node());

    const g = this.svg.append("g");

    this.gLink = g
      .append("g")
      .attr("fill", "none")
      .attr("stroke", "white")
      .attr("stroke-opacity", 0.4)
      .attr("stroke-width", 1.5);

    this.gNode = g
      .append("g")
      .attr("cursor", "pointer")
      .attr("pointer-events", "all");

    const handleZoom = (e) => g.attr("transform", e.transform);
    const zoom = d3
      .zoom()
      .on("zoom", handleZoom)
      .scaleExtent([MIN_SCALE, MAX_SCALE]);

    d3.select("svg").call(zoom);

    setTimeout(() => {
      // this.update(root);
      // this.raiseEvent("tree_ready", []);
    }, 100);

    this.collector = {};

    return this.svg.node();
  }

  async update(source) {
    // console.log("update");
    const root = this.root;

    const tree = this.tree;
    const duration = 250; // d3.event && d3.event.altKey ? 2500 : 250;
    const nodes = root.descendants().reverse();
    const links = root.links();
    // console.log("REVERSED", nodes);

    nodes.forEach((node) => {
      // console.log("revers node", node);
      if (node.parent && node.data.ecal && this.GET_ECAL) {
        node.parent.data.ecal = true;
      }
    });

    // Compute the new tree layout.
    tree(root);

    let left = root;
    let right = root;
    root.eachBefore((node) => {
      if (node.x < left.x) left = node;
      if (node.x > right.x) right = node;
    });

    const transition = this.svg
      .transition()
      .duration(duration)
      .attr("viewBox", [
        -this.margin.left,
        left.x - this.margin.top,
        this.width,
        this.height,
      ])
      .tween(
        "resize",
        window.ResizeObserver ? null : () => () => this.svg.dispatch("toggle")
      );

    // Update the nodes…
    const node = this.gNode.selectAll("g").data(nodes, (d) => d.id);

    // Enter any new nodes at the parent's previous position.
    const nodeEnter = node
      .enter()
      .append("g")
      .attr("transform", (d) => `translate(${source.y0},${source.x0})`)
      .attr("fill-opacity", 0)
      .attr("stroke-opacity", 0)
      .on("click", (event, d) => {
        d.children = d.children ? null : d._children;
        this.update(d);
      });

    nodeEnter
      .append("circle")
      .attr("r", (d) => {
        // if (d._children) {
        //   console.log(d);
        // }
        return d._children && d.imageURL ? 14 : 4;
      })
      .attr("fill", (d) =>
        d._children
          ? d.data.ecal && this.GET_ECAL
            ? ECAL_COLOR
            : "white"
          : d.data.ecal && this.GET_ECAL
          ? ECAL_COLOR
          : "rgb(54, 222, 222)"
      )
      .attr("stroke-width", 10);

    // nodeEnter
    //   .append("image")
    //   .attr("xlink:href", (d) => d.imageURL)
    //   .attr("x", "-12px")
    //   .attr("y", "-12px")
    //   .attr("width", "24px")
    //   .attr("height", "24px")
    //   .on("click", this.handleClick.bind(this));

    nodeEnter
      .append("text")
      .attr("dy", "0.31em")
      .attr("x", (d) => (d._children ? -6 : 6))
      .attr("text-anchor", (d) => (d._children ? "end" : "start"))
      .text((d) => (d.imageURL ? "" : d.data.name))
      .call((selection) => {
        selection.each(function (d) {
          d.bbox = this.getBBox();
        });
      })
      .attr("fill", (d) =>
        d.data.name == "CREATIVE CODING"
          ? "white"
          : d._children
          ? d.data.ecal && this.GET_ECAL
            ? ECAL_COLOR
            : "black"
          : d.data.ecal && this.GET_ECAL
          ? ECAL_COLOR
          : "rgb(54, 222, 222)"
      )
      .on("click", this.handleClick.bind(this));
    // .clone(true)
    // .lower()
    // .attr("stroke-linejoin", "round")
    // .attr("stroke-width", 3)
    // .attr("stroke", "white");

    nodeEnter
      .insert("rect", "text")
      .attr("width", (d) => {
        return d.bbox.width;
      })
      .attr("height", (d) => {
        return d.bbox.height;
      })
      .attr("x", (d) => {
        return -d.bbox.width - 6;
      })
      .attr("y", (d) => {
        return -d.bbox.height + 7;
      })
      .style("fill", (d) =>
        d._children ? "rgb(61, 100, 120)" : "transparent"
      );

    // Transition nodes to their new position.
    const nodeUpdate = node
      .merge(nodeEnter)
      .transition(transition)
      .attr("transform", (d) => `translate(${d.y},${d.x})`)
      .attr("fill-opacity", 1)
      .attr("stroke-opacity", 1);

    // Transition exiting nodes to the parent's new position.
    const nodeExit = node
      .exit()
      .transition(transition)
      .remove()
      .attr("transform", (d) => `translate(${source.y},${source.x})`)
      .attr("fill-opacity", 0)
      .attr("stroke-opacity", 0);

    // Update the links…
    const link = this.gLink.selectAll("path").data(links, (d) => d.target.id);
    // console.log(link);
    // Enter any new links at the parent's previous position.
    const linkEnter = link
      .enter()
      .append("path")
      .attr("stroke", (d) => {
        if (d.target.data.ecal && this.GET_ECAL)
          this.collector[d.source.data.name] = d;
        return d.target.data.ecal && this.GET_ECAL ? ECAL_COLOR : "white";
      })
      .attr("stroke-opacity", (d) =>
        d.target.data.ecal && this.GET_ECAL ? 1 : 0.4
      )
      .attr("stroke-width", (d) =>
        d.target.data.ecal && this.GET_ECAL ? 3 : 1.5
      )
      .attr("d", (d) => {
        const o = { x: source.x0, y: source.y0 };
        return this.diagonal({ source: o, target: o });
      })
      .attr("class", "link");

    // Transition links to their new position.
    link.merge(linkEnter).transition(transition).attr("d", this.diagonal);

    // Transition exiting nodes to the parent's new position.
    link
      .exit()
      .transition(transition)
      .remove()
      .attr("d", (d) => {
        const o = { x: source.x, y: source.y };
        return this.diagonal({ source: o, target: o });
      });

    // Stash the old positions for transition.
    root.eachBefore((d) => {
      d.x0 = d.x;
      d.y0 = d.y;
    });
    // this.update(this.root);

    // force update color
    //
  }

  // clearJourney() {
  //   this.svgContainer.innerText = "";
  // }

  // toggleChildren(d) {
  //   if (d._children) {
  //     d.children = d._children;
  //     d._children = null;
  //   } else if (d.children) {
  //     d._children = d.children;
  //     d.children = null;
  //   }
  //   return d;
  // }

  handleClick(e, d) {
    // console.log(d);
    if (!d._children && d.data.hasOwnProperty("url"))
      this.handleImageClick(e, d);
  }

  handleImageClick(event, d) {
    this.clickedNode = d;
    window.open(d.data.url).focus();
    // this.raiseEvent("image_click", [d.data]);
  }

  checkChildren(node) {
    node._children.forEach(async (n) => {
      n._children = n._prevChildren;
      n.children = n._prevChildren;
      if (n._children) {
        this.checkChildren(n);
      }
    });
  }

  //debug
  openAll() {
    // this.GET_ECAL = true;
    this.root.descendants().forEach(async (d, i) => {
      this.checkChildren(d);
    });
  }
}
