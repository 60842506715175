/**
CREATIVE CODING
---
Kill server : CTRL + C
Start server : npm run start
Start secure server : npm run start-https
Final build : npm run build
---
To generate new certificate for https connection with external device run :
#sh
mkcert 0.0.0.0 localhost 127.0.0.1 yourLocalIP ::1
mv 0.0.0.0+4-key.pem certificate.key
mv 0.0.0.0+4.pem certificate.cert
**/

import Playground from "@onemorestudio/playgroundjs";
import LinearTree from "./LinearTree";
import jsonFile from "../static/json/data.json";

export default class App {
  //extends Playground {
  constructor() {
    // super();
    // this.draw();

    this.container = document.getElementById("tree-container");
    this.tree = new LinearTree(this.container);
    this.tree.initGraph(jsonFile);
    this.tree.update(this.tree.root);

    // ecal buton
    const ecal_btn = document.querySelector(".ecal");
    // general buton
    const all_btn = document.querySelector(".all");
    // close buton
    const close_btn = document.querySelector(".close");

    // event
    ecal_btn.addEventListener("click", (e) => {
      this.tree.svg.remove();
      this.tree.initGraph(jsonFile);
      this.tree.GET_ECAL = true;
      this.tree.openAll();
      this.tree.update(this.tree.root);
    });
    all_btn.addEventListener("click", (e) => {
      this.tree.svg.remove();
      this.tree.initGraph(jsonFile);
      this.tree.GET_ECAL = false;
      this.tree.openAll();
      this.tree.update(this.tree.root);
    });
    close_btn.addEventListener("click", (e) => {
      this.tree.svg.remove();
      this.tree.initGraph(jsonFile);
      this.tree.GET_ECAL = false;
      // this.tree.openAll();
      this.tree.update(this.tree.root);
    });

    // setTimeout(() => {
    //   this.tree.svg.remove();
    //   this.tree.initGraph(jsonFile);
    //   this.tree.openAll();
    //   this.tree.update(this.tree.root);
    // }, 2000);
  }
}

/**
 *  NOTES
 *  -------------
 *  What is missing ?
 *  More tangible tools ?
 *  Is Hacking a real section ?
 *  Is repetition a problem ?
 *
 */
